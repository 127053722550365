<template>
  <Login @get_user_password="login" :login_loading="login_loading">影院人员入口</Login>
</template>

<script>
import Login from "../components/Login";
import { Login as userlogin } from "../axios/api"
import { Toast } from 'vant';
export default {
  name: "CinemaLogin",
  components:{
    Login,
  },
  /*路由拦截 判断缓存有无用户信息*/
  beforeRouteEnter(to, from ,next) {
    if(localStorage.getItem("token") && localStorage.getItem("identity") && localStorage.getItem("userName")){
      //缓存中存在,默认用户已经登录
      if(localStorage.getItem("identity")==='0'){
        next("/unMemberUser")
      }else if(localStorage.getItem("identity")==='1'){
        next("/memberUser")
      }else{
      //  如果identity===3 检测人员登录
      //   清除所有账户信息
        localStorage.removeItem("token")
        localStorage.removeItem("identity")
        localStorage.removeItem("userName")
        next(true)
      }
    }else{
      next(true)
    }
  },
  data(){
    return{
      login_loading: false
    }
  },
  methods:{
    login(u,p){
      /*首先判断账户密码是否为空*/
      if(u && p){
        this.login_loading=true
        userlogin({type:"member",number:u,password:p}).then(respose=>{
          if(respose.state==='1'){
            Toast.success({message:"登录成功"})
            /*Message.success(respose.data.name+"欢迎登录");*/
            localStorage.setItem("userName",respose.data.name);//用户名字
            localStorage.setItem("token",respose.data.token);//用户token
            localStorage.setItem("identity",respose.data.vip);//用户身份
            if(respose.data.vip==='0'){
              this.$router.push("/unMemberUser")
            }else if (respose.data.vip==='1'){
              this.$router.push("/memberUser")
            }
          }else{
            //登录失败
            Toast({message:"账户密码错误",icon:"cross"})
          }
          this.login_loading=false
        }).catch(error=>{
          console.log(error)
        })
      }else{
        Toast.fail("请先输入账户密码")
      }
    }
  }
}
</script>

